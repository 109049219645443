<template>
  <div class="subhonor">
    <div class="sub-banner bg" />
    <div class="wrapper">
      <div class="bread">
        <div class="icon"><i class="el-icon-caret-right" /></div>
        <div class="text">您的位置：首页  <i>></i>  <span>荣誉资质</span></div>
      </div>
      <div class="sub-menu">
        <a href="/baseHome/about" title="公司简介">公司简介</a>
        <a href="/baseHome/culture" title="企业文化">企业文化</a>
        <a href="/baseHome/honor" title="荣誉资质" class="on">荣誉资质</a>
        <a href="/baseHome/cooperation" title="合作共赢">合作共赢</a>
        <a href="/baseHome/joinus" title="加入我们">加入我们</a>
      </div>
    </div>
    <div class="sub-title">荣誉资质</div>
    <div class="honor">
      <ul>
        <li><img src="../../assets/images/ho2.jpg"></li>
        <li><img src="../../assets/images/ho3.jpg"></li>
        <li><img src="../../assets/images/ho4.jpg"></li>
        <li><img src="../../assets/images/ho5.jpg"></li>
      </ul>
    </div>
  </div>

</template>

<script>

export default {
  name: '',
  components: {
  },
  data() {
    return {

    }
  },

  mounted() {

  },
  methods: {

  }
}
</script>

<style lang="scss">
@import "./../../assets/scss/sub.scss";
.subhonor {
  .bg{background:url(./../../assets/images/aboutbg.jpg) }
  .honor{
    width: 1400px; height: 570px; margin: 0 auto; padding:0 195px; box-sizing: border-box;
    background:url(./../../assets/images/honor1.jpg) no-repeat right bottom;
    li{ float: left; width: 329px; height: 207px; margin:10px 50px 40px 0;  overflow: hidden; border:#e7e7e7 solid 1px;}
    li img{width: 329px; height: 207px; transition: all .5s; -webkit-transition: all .5s; -moz-transition: all .5s; -ms-transition: all .5s; -o-transition: all .5s;}
    li:hover img{width: 349px; height: 220px; margin-top:-7px; margin-left:-10px;}
  }
}
</style>
